export const getDefaultLocale = () => {
  const hasEnglishLocale = typeof window !== 'undefined' && location?.pathname?.includes('/en');

  if (hasEnglishLocale && typeof window !== 'undefined') {
    return 'en';
  }

  const defaultLocale = typeof window !== 'undefined' && window.localStorage.getItem('locale');

  return defaultLocale || 'ar';
};

export function applyRTLStyles(styles, isRTL) {
  if (isRTL) {
    return {
      ...styles,
      textAlign: 'right',
      paddingLeft: '0', // Override left padding for RTL
      paddingRight: '0px', // Add padding on the right for RTL
    };
  }
  return styles;
}
